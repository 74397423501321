import {focusInputGroups} from "../../../default/global/js/checkInputGroups";
import {excludeModal, triggerModal} from "../../../default/global/js/modal";
import {initJumpmarks} from "../../../default/global/js/jumpmark";
import {initPhoneCountryFields} from "../../../default/global/js/phoneCountryField";
import {initInputMaskDate} from "../../../default/global/js/inputMaskDate";
import {lottieLoader} from "../../../default/global/js/lottieLoader";
import {setHeightForElement} from "./custom/bgHeightCalc";
import {initSlidecontents} from "../../../default/global/js/slidecontent";
import {initRecaptchaForms} from "../../../default/global/js/recaptchaForm";

document.addEventListener("DOMContentLoaded", (event) => {
    focusInputGroups();
    triggerModal();
    excludeModal();
    initJumpmarks();
    initPhoneCountryFields();
    initInputMaskDate();
    lottieLoader();
    initSlidecontents();
    initRecaptchaForms();
    setTimeout(function (){
        setHeightForElement();
    },400);

});
