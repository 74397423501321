export function setHeightForElement() {
    // Höhe des Headers abrufen
    var header = document.getElementById('header');
    var headerHeight = header.offsetHeight;
    console.log(headerHeight);

    var footer = document.getElementById('footer');
    var footerHeight = footer.offsetHeight;
    console.log(footerHeight);

    // Neue Höhe berechnen (Header-Höhe - 100vh)
    var newHeight =  window.innerHeight - headerHeight - footerHeight;

    if (window.innerWidth > 1024) {
        // Element mit der Klasse 'js-calcHeight' auswählen und die neue Höhe setzen
        var calcHeightElement = document.querySelector('.loginBg');
        var mainContent = document.querySelector('.mainContent');
        if(calcHeightElement) {
            calcHeightElement.style.height = newHeight + 'px';
            mainContent.style.height = newHeight + 'px';
        }
    }
}